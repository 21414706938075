<template>
  <v-row justify="center">
    <v-dialog v-model="shower" persistent max-width="500">
      <v-card scrollable persistent>
          <v-card-title>Add Relation Inbox <v-spacer></v-spacer>
            <v-icon :color="color.theme" dense dark size="35">device_hub</v-icon>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
              <v-layout row wrap align-center justify-center>
                  <v-flex xs11 lg11 ma-5>
                    <v-card>
                        <v-container fluid >
                            <v-row align="center">
                            <v-col cols="12">
                                <v-layout row wrap justify-center>
                                    <v-flex lg10 xs10 class="text-center">
                                        <v-text-field
                                        label="NameRelation"
                                        v-model="namerelation"
                                        persistent-hint
                                        outlined
                                        dense
                                        >
                                        </v-text-field>
                                    </v-flex>
                                </v-layout>
                                <v-layout row wrap justify-center>
                                    <v-flex lg10 xs10 class="text-center">
                                        <v-select
                                        label="Relation"
                                        :items=statusrelation
                                        v-model="relation"
                                        persistent-hint
                                        outlined
                                        dense
                                        >
                                        </v-select>
                                    </v-flex>
                                </v-layout>
                            </v-col>
                            </v-row>
                        </v-container>
                    </v-card>
                  </v-flex>
              </v-layout>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
              <v-spacer></v-spacer>
             <v-btn
              color="red"
              outlined
              @click="$emit('closedialog'),relation='', namerelation=''"
            >{{$t('uploadfolder.buttonclose') }}</v-btn>
            <v-btn
            @click="apiAdd()"
            :color="color.theme"
            dark
            >{{$t('changeName.submit')}}</v-btn>

          </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
import gbfGenerate from "@/globalFunctions/generateAuthorize";
const editsharefile = () => import("../optional/dialog-editsharefile");
const Toast = Swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  timer: 3000
});
  export default {
    props: ["show"],
    data () {
      return {
       relation:'',
       namerelation:'',
       statusrelation : [],
       dialog: false,
       fileinbox:[],
      }
    },
    computed:{
    ...mapState(["username", "authorize", "account_active", "color"]),
    ...mapState({ processloader: "loading" }),
    ...mapGetters([
      "dataUsername",
      "dataAuthorize",
      "dataAccountActive",
      "dataAccesstoken",
      "dataBusinessProfile",
      "dataCitizenProfile",
      "dataDepartmentAccessId",
      "dataAccountId"
    ]),
       shower: {
      get() {
        if (this.show === true) {
            // this.checkstatusrelation()
            this.loaddata()
        }
        return this.show;
      }
    }
    },
    methods:{
       async apiAdd(){
            let newdata = ''
            let payload;
            if(this.namerelation === ""){
                Toast.fire({
                icon: "error",
                title: "Error Please enter name "
              });
            }
            else if(this.relation === ""){
                Toast.fire({
                icon: "error",
                title: "Error Please Select Relation"
              });
            }else{
            for(let i = 0; i <this.fileinbox.length; i++){
                if(this.relation === this.fileinbox[i].filename){
                    this.newdata = this.fileinbox[i].inbox_id
                }
            }
            payload={
                inbox_root:this.newdata,
                account_id:this.dataAccountId,
                relation_name:this.namerelation
            }
            console.log("newdata",payload);
            let auth = await gbfGenerate.generateToken();
             await this.axios
                .post(process.env.VUE_APP_SERVICE_DOWNLOAD_API + "/api/insert_relation_inbox",payload, { headers: { Authorization: auth.code },},)
                .then(response =>{
                    if (response.data.status === "OK") {
                        Toast.fire({
                            icon: "success",
                            title: "Success"
                         });
                        this.$emit('closedialog')
                        this.$emit('loaddata')
                        this.relation=''
                        this.namerelation=''
                    }else{
                        Toast.fire({
                            icon: "error",
                            title: "Error"
                         });
                    }
                    
                })
            }
        },
       async loaddata(){
         let payload
           payload = {
         limit:"",
         offset: "",
         receiver_id:[this.dataAccountActive.business_info["business_id"]],
         receiver_type:"2",
         filename:"",
         fileextension:"",
         doc_no_start:"",
         doc_no_end: "",
         doc_date_start:"",
         doc_date_end:"",
         doc_type: "",
         cre_dtm_start:"",
         cre_dtm_end:"",
         sender_name:"",
         system:this.$route.query.systemid,
         sellerTaxid:"",
         sellerBranchid: "",
         receiver_name: "",
         receiverTaxid:"",
         receiverBranchid:"",
         status:""
           }
           let auth = await gbfGenerate.generateToken();
        this.axios
        .post(
          process.env.VUE_APP_SERVICE_GET_INBOX + "/api/get_file_inbox_child",
          payload,{headers: { Authorization: auth.code },}
        )        
        .then(response=>{
          if (response.data.status === "OK") {
            this.fileinbox = response.data.result.data
            this.checkstatusrelation()
          }
          else{

          }
          console.log(this.fileinbox);
        })     
        },
        checkstatusrelation(){
            this.statusrelation = []
            for(let i = 0; i <this.fileinbox.length; i++){
                if(this.fileinbox[i].relation_status === 2 && this.fileinbox[i].status === "active"){
                  this.statusrelation.push(this.fileinbox[i].filename)
                  console.log("statusrelation",this.statusrelation);
                }
            }
        }
    }
  }
</script>